import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProjectList from "../components/project-list"
import { StaticImage } from "gatsby-plugin-image"

const HomePage = ({ data, location }) => {
  const projects = data.allMdx.nodes
  return (
    <Layout location={location}>
      <div className="flex flex-wrap sm:flex-nowrap items-center pt-0 sm:pt-20 pb-20 sm:pb-44">
        <StaticImage
          src="../images/profile_index.png"
          alt="Menghan's profile image"
          placeholder="tracedSVG"
          className="w-44 h-44 flex-shrink-0"
        />
        <p className="font-normal text-2xl sm:text-4xl ml-0 sm:ml-20 leading-normal sm:leading-normal mr-10 sm:mr-20 mt-12 sm:mt-0">
          I'm <span className="font-semibold text-menghan">Menghan</span>,
          currently designing Chrome OS at{" "}
          <span className="font-semibold">Google</span>. Previously designing at{" "}
          <span className="font-semibold">Zhihu</span>.
        </p>
      </div>
      <div>
        <Seo title="Home" />
        <ProjectGroups groupedProjects={groupProjectsByCompany(projects)} />
      </div>
    </Layout>
  )
}

const groupProjectsByCompany = projects =>
  projects.reduce((grouped, project) => {
    const key = project.frontmatter.company
    grouped[key] = grouped[key] || []
    grouped[key].push(project)
    return grouped
  }, {})

const COMPANIES = [
  {
    key_name: "Google",
    display_name: "Chrome OS",
    logo: "/logo_google.svg",
    desc: "Google's speedy, simple and secure OS for EDU, enterprise, and customers",
  },
  {
    key_name: "Zhihu",
    display_name: "Zhihu",
    logo: "/logo_zhihu.svg",
    desc: "China's largest social platform for questions and answers with 200+ million users",
  },
]

const ProjectGroups = ({ groupedProjects }) => {
  return (
    <ul>
      {COMPANIES.map(company => {
        return (
          <li>
            <ProjectsOfCompany
              company={company}
              projects={groupedProjects[company["key_name"]]}
            />
          </li>
        )
      })}
    </ul>
  )
}

const ProjectsOfCompany = ({ company, projects }) => {
  return (
    <div className="mt-16">
      <div className="flex flex-row items-center">
        <img
          src={company.logo}
          alt={company.display_name}
          className="h-8 sm:h-11"
        />
        <h2 className="ml-6 text-black font-medium text-3xl sm:text-4xl">
          {company.display_name}
        </h2>
      </div>
      <ProjectList projects={projects} />
    </div>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    allMdx {
      nodes {
        fields {
          slug
        }
        frontmatter {
          company
          index
          subtitle
          title
          coverImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
